import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const UserRequestEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput source="user_ID" reference="users">
        <SelectInput optionText="email" />
      </ReferenceInput>
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);

export default UserRequestEdit;
