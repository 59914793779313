import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
} from "react-admin";

const TaskList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="task_ID" label="ID" />
        <TextField source="thumbnail" label="Thumbnail" />
        <TextField source="task_Name" label="Task Name" />
        <DateField source="due_Date_Time" label="Due Date & Time" />
        <TextField source="task_JSON" label="Task JSON" />
        <TextField source="task_State" label="Task State" />
        <DateField source="finish_Date_Time" label="Finish Date & Time" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default TaskList;
