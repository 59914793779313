import React from "react";
import {
  List,
  Datagrid,
  TextField,
  // EditButton,
  DeleteButton,
  Filter,
  SearchInput,
  DateField,
} from "react-admin";

const TokenFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="User ID" source="user_ID" resettable alwaysOn />
  </Filter>
);

const RefreshTokenList = (props) => {
  return (
    <List {...props} filters={<TokenFilter />}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="refreshToken" label="Refresh Token" />
        <TextField source="user_ID" label="user_ID" />
        <DateField source="createdAt" label="Created At" />
        {/* <EditButton /> */}
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default RefreshTokenList;
