import React from "react";
import { Admin, Resource } from "react-admin";
// import restProvider from "ra-data-simple-rest";
import authProvider from "./authProvider";
import CompanyList from "./components/CompanyList";
import CompanyRoleUsersList from "./components/CompanyRoleUsersList";
import RefreshTokenList from "./components/RefreshTokenList";
import RoleList from "./components/RoleList";
import TaskList from "./components/TaskList";
import UserList from "./components/UserList";
import AdminList from "./components/AdminList";
import UserRequestList from "./components/UserRequestList";
import CompanyUsersList from "./components/CompanyUsersList";
import dataProvider from "./dataProvider";
import UserEdit from "./components/editPages/UserEdit";
import CompanyEdit from "./components/editPages/CompanyEdit";
import AdminEdit from "./components/editPages/AdminEdit";
// import CompanyRoleUsersEdit from "./components/editPages/CompanyRoleUsersEdit";
// import RefreshTokenEdit from "./components/editPages/RefreshTokenEdit";
import RoleEdit from "./components/editPages/RoleEdit";
import TaskEdit from "./components/editPages/TaskEdit";
import UserRequestEdit from "./components/editPages/UserRequestEdit";
import CompanyCreate from "./components/createPages/CompanyCreate";
import RefreshTokenCreate from "./components/createPages/RefreshTokenCreate";
import RoleCreate from "./components/createPages/RoleCreate";
import TaskCreate from "./components/createPages/TaskCreate";
import UserCreate from "./components/createPages/UserCreate";
import UserRequestCreate from "./components/createPages/UserRequestCreate";
import CompanyRoleUsersCreate from "./components/createPages/CompanyRoleUsersCreate";
import AdminCreate from "./components/createPages/AdminCreate";
import CompanyUserCreate from "./components/createPages/CompanyUserCreate";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import RefreshIcon from "@mui/icons-material/Refresh";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TaskIcon from "@mui/icons-material/Task";
import GroupIcon from "@mui/icons-material/Group";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource
        name="companies"
        list={CompanyList}
        edit={CompanyEdit}
        create={CompanyCreate}
        icon={BusinessIcon}
      />
      <Resource
        name="company-role-users"
        list={CompanyRoleUsersList}
        // edit={CompanyRoleUsersEdit}
        create={CompanyRoleUsersCreate}
        icon={ManageAccountsIcon}
      />
      <Resource
        name="refresh-tokens"
        list={RefreshTokenList}
        // edit={RefreshTokenEdit}
        create={RefreshTokenCreate}
        icon={RefreshIcon}
      />
      <Resource
        name="company-admins"
        list={AdminList}
        edit={AdminEdit}
        create={AdminCreate}
        icon={AdminPanelSettingsIcon}
      />
      <Resource
        name="roles"
        list={RoleList}
        edit={RoleEdit}
        create={RoleCreate}
        icon={BadgeIcon}
      />
      <Resource
        name="tasks-templates"
        list={TaskList}
        edit={TaskEdit}
        create={TaskCreate}
        icon={TaskIcon}
      />
      <Resource
        name="company-users"
        list={CompanyUsersList}
        // edit={TaskEdit}
        create={CompanyUserCreate}
        icon={TaskIcon}
      />
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        icon={GroupIcon}
      />
      <Resource
        name="user-requests"
        list={UserRequestList}
        edit={UserRequestEdit}
        create={UserRequestCreate}
        icon={RequestPageIcon}
      />
    </Admin>
  );
}

export default App;
