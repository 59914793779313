import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const CompanyCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="companyName" />
        <TextInput type="password" source="password" />
        <TextInput source="apiToken" />
      </SimpleForm>
    </Create>
  );
};

export default CompanyCreate;
