import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const AdminEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput disabled label="First Name" source="user.first_Name" />
      <TextInput disabled label="User ID" source="user.user_ID" />
      <TextInput disabled label="User email" source="user.email" />
      <BooleanInput label="Is Super Admin" source="isSuper" />
      <TextInput disabled label="Company ID" source="company.company_ID" />
      <TextInput disabled label="Company name" source="company.companyName" />
    </SimpleForm>
  </Edit>
);

export default AdminEdit;
