import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
    // DateInput,
    // ReferenceInput,
    // SelectInput,
} from "react-admin";

const CompanyRoleUsersCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="company_ID" label="Company ID"/>
        <TextInput source="user_ID" label="User ID"/>
        <TextInput source="role_ID" label="Role ID"/>
        {/* <ReferenceInput source="user_ID" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput> */}
        {/* <ReferenceInput source="company_ID" reference="companies">
          <SelectInput optionText="companyName" />
        </ReferenceInput> */}
      </SimpleForm>
    </Create>
  );
};

export default CompanyRoleUsersCreate;
