import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  DeleteButton,
  Filter,
  SearchInput,
} from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="User email" source="email" resettable alwaysOn />
    <SearchInput
      placeholder="First name"
      source="first_Name"
      resettable
      alwaysOn
    />
    <SearchInput
      placeholder="Company name"
      source="company_Name"
      resettable
      alwaysOn
    />
  </Filter>
);

const CompanyUsersList = (props) => {
  return (
    <List {...props} filters={<UserFilter />}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="user_ID" label="user ID" />
        <TextField source="user.first_Name" label="First Name" />
        <TextField source="user.email" label="Email" />
        <TextField source="company_ID" label="Company ID" />
        <TextField source="company.companyName" label="Company Name" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default CompanyUsersList;
