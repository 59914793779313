import React from "react";
import { Edit, SimpleForm, TextInput, BooleanInput } from "react-admin";

const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="first_Name" />
      <TextInput source="second_Name" />
      <TextInput source="email" />
      <BooleanInput source="isActive" />
      <BooleanInput source="verified_Login" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
