import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const RoleEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="role_ID" label="Role ID" />
      <TextInput source="role_Name" label="Role Name" />
    </SimpleForm>
  </Edit>
);

export default RoleEdit;
