import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";

const UserRequestList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="request_JSON" label="Request JSON" />
        <TextField source="user_ID" label="user ID" />
        <TextField source="company_ID" label="company ID" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default UserRequestList;
