import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  SearchInput,
  DateField,
} from "react-admin";

const RoleFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder="role name"
      source="role_Name"
      resettable
      alwaysOn
    />

    <SearchInput placeholder="Role ID" source="role_ID" resettable alwaysOn />
  </Filter>
);

const RoleList = (props) => {
  return (
    <List {...props} filters={<RoleFilter />}>
      <Datagrid>
        <TextField source="role_ID" label="ID" />
        <TextField source="role_Name" label="Role Name" />
        <DateField source="createdAt" label="Created At" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default RoleList;
