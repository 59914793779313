const authProvider = {
  login: async ({ username, password }) => {
    return fetch("https://nalipka.azurewebsites.net/adminauth/login", {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
    });
  },
  logout: async () => {
    return fetch("https://nalipka.azurewebsites.net/adminauth/logout", {
      method: "POST",
      credentials: "include",
    }).then(() => {});
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    return fetch("https://nalipka.azurewebsites.net/adminauth/check-auth", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          return Promise.reject("Authentication failed");
        }
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject("Network error");
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};

export default authProvider;
