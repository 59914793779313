import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  DateField,
} from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder="Company name"
      source="companyName"
      resettable
      alwaysOn
    />

    <SearchInput
      placeholder="Company ID"
      source="company_ID"
      resettable
      alwaysOn
    />
  </Filter>
);

const CompanyList = (props) => {
  return (
    <List {...props} filters={<UserFilter />}>
      <Datagrid>
        <TextField source="company_ID" label="ID" />
        <TextField source="companyName" label="Company Name" />
        <DateField source="createdAt" label="Created At" />
        {/* <TextField source="apiToken" label="API Token" /> */}
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default CompanyList;
