import React from "react";
import {
  List,
  Datagrid,
  TextField,
  // EditButton,
  DeleteButton,
  Filter,
  SearchInput,
  DateField,
} from "react-admin";

const CompanyRoleUsersFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder="Company name"
      source="companyName"
      resettable
      alwaysOn
    />

    <SearchInput placeholder="User email" source="email" resettable alwaysOn />
  </Filter>
);

const CompanyRoleUsersList = (props) => {
  return (
    <List {...props} filters={<CompanyRoleUsersFilter />}>
      <Datagrid>
        <TextField source="id" label="association id" />
        <TextField source="company_ID" label="company id" />
        <TextField source="company.companyName" label="company name" />
        <TextField source="user_ID" label="user id" />
        <TextField source="user.email" label="user email" />
        <TextField source="role_ID" label="role id" />
        <TextField source="role.role_Name" label="role name" />
        <DateField source="createdAt" label="Created At" />
        {/* <EditButton /> */}
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default CompanyRoleUsersList;
