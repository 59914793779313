import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  email,
  useRedirect,
  useNotify,
} from "react-admin";

const UserCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("User created!");
    redirect("/users");
  };

  const onError = (error) => {
    notify(`Error: User not created. ${error.message}`, "warning");
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess, onError }}>
      <SimpleForm>
        <TextInput source="firstName" />
        <TextInput source="secondName" />
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput source="password" />
        <TextInput source="verification_Link_Token" />
        <TextInput source="resetToken" />
        <BooleanInput source="isActive" />
        <BooleanInput source="verified_Login" />
        <TextInput source="socket_Url" />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
