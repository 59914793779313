import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const TaskEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
    </SimpleForm>
  </Edit>
);

export default TaskEdit;
