import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  //   ReferenceInput,
  //   SelectInput,
} from "react-admin";

const RefreshTokenCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        {/* <TextInput source="refreshToken" /> */}
        <TextInput source="email" />
        {/* <ReferenceInput source="user_ID" reference="users"> */}
        {/* <SelectInput optionText="email" /> */}
        {/* </ReferenceInput> */}
      </SimpleForm>
    </Create>
  );
};

export default RefreshTokenCreate;
