import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
//   ReferenceInput,
//   SelectInput,
} from "react-admin";
import { FileInput, FileField } from "react-admin";

const TaskCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="thumbnail" />
        <TextInput source="task_Name" />
        <TextInput source="task_State" />
        <DateInput source="due_Date_Time" />
        <DateInput source="finish_Date_Time" />
        <TextInput source="user_ID" />
        <TextInput source="company_ID" />
        {/* <ReferenceInput source="user_ID" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput source="company_ID" reference="companies">
          <SelectInput optionText="companyName" />
        </ReferenceInput> */}
        <FileInput
          multiple={false}
          accept="application/json"
          source="task_JSON"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default TaskCreate;
