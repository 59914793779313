import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  DeleteButton,
    Filter,
    SearchInput,
} from "react-admin";

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput placeholder="User email" source="email" resettable alwaysOn />
    <SearchInput
      placeholder="First name"
      source="first_Name"
      resettable
      alwaysOn
    />
    <SearchInput
      placeholder="Second name"
      source="second_Name"
      resettable
      alwaysOn
    />
  </Filter>
);

const UserList = (props) => {
  return (
    <List {...props} filters={<UserFilter />}>
      <Datagrid>
        <TextField source="user_ID" label="ID" />
        <TextField source="first_Name" label="First Name" />
        <TextField source="second_Name" label="Second Name" />
        <TextField source="email" label="Email" />
        {/* <TextField
          source="verification_Link_Token"
          label="Verification Token"
        /> */}
        {/* <TextField source="resetToken" label="Reset Token" /> */}
        <DateField source="registration_Date" label="Registration Date" />
        <BooleanField source="isActive" label="Active" />
        <BooleanField source="verified_Login" label="Verified Login" />
        <TextField source="socket_Url" label="Socket URL" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
