import { fetchUtils } from "ra-core";
import simpleRestProvider from "ra-data-simple-rest";

const apiUrl = "https://nalipka.azurewebsites.net/admindata";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  options.credentials = "include";
  return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = simpleRestProvider(apiUrl, httpClient);

const dataProvider = {
  ...baseDataProvider,
  getOne: async (resource, params) => {
    const response = await baseDataProvider.getOne(resource, params);
    console.log("API response:", response);
    if (resource === "companies") {
      const dataValues = response.data;

      response.data = {
        id: dataValues.company_ID,
        companyName: dataValues.companyName,
        // apiToken: dataValues.apiToken,
      };
    }
    if (resource === "users") {
      const dataValues = response.data;

      response.data = {
        id: dataValues.user_ID,
        email: dataValues.email,
        first_Name: dataValues.first_Name,
        second_Name: dataValues.second_Name,
        // registration_Date: dataValues.registration_Date,
        isActive: dataValues.isActive,
        verified_Login: dataValues.verified_Login,
      };
    }
    // if (resource === "company-role-user") {
    //   const dataValues = response.data;

    //   response.data = {
    //     id: dataValues.id,
    //     companyName: dataValues.companyName,
    //     company_ID: dataValues.company_ID,
    //     role_Name: dataValues.role_Name,
    //     role_ID: dataValues.role_ID,
    //     email: dataValues.email,
    //     user_ID: dataValues.user_ID,
    //   };
    // }
    if (resource === "roles") {
      const dataValues = response.data.dataValues;
      console.log("these are the data values", dataValues);

      response.data = {
        id: dataValues.role_ID,
        role_Name: dataValues.role_Name,
        role_ID: dataValues.role_ID,
      };
    }
    if (resource === "company-admin") {
      const dataValues = response.data.dataValues;
      console.log("these are the data values", dataValues);

      response.data = {
        id: dataValues.id,
        first_Name: dataValues.user.first_Name,
        user_ID: dataValues.user.user_ID,
        email: dataValues.user.email,
        isSuper: dataValues.isSuper,
        company_ID: dataValues.company.company_ID,
        companyName: dataValues.company.companyName,
        // id: dataValues.role_ID,
        // role_Name: dataValues.role_Name,
        // role_ID: dataValues.role_ID,
      };
    }
    return response;
  },
  getList: async (resource, params) => {
    const response = await baseDataProvider.getList(resource, params);
    if (resource === "company-role-user") {
      return {
        ...response,
        data: response.data.map((record) => ({
          id: record.id,
          companyName: record.company.companyName,
          email: record.user.email,
          role_Name: record.role.role_Name,
          createdAt: record.createdAt,
        })),
      };
    }
    console.log(response.data, "LOGGING"); /////////////////////////////////////////

    if (resource === "company-admin") {
      console.log(response.data[0]);
      return {
        ...response,
        data: response.data.map((record) => ({
          id: record.id,
          isSuper: record.isSuper,
          userId: record.user_Id,
          companyId: record.companyId,
          // companyName: record.company.companyName,
          // email: record.user.email,
          // first_Name: record.user.first_Name,
          createdAt: record.createdAt,
        })),
      };
    }

    if (resource === "companies") {
      return {
        ...response,
        data: response.data.map((company) => ({
          ...company,
          id: company.company_ID,
        })),
      };
    }
    if (resource === "users") {
      return {
        ...response,
        data: response.data.map((user) => ({
          ...user,
          id: user.user_ID,
        })),
      };
    }
    if (resource === "roles") {
      return {
        ...response,
        data: response.data.map((role) => ({
          ...role,
          id: role.role_ID,
        })),
      };
    }
    if (resource === "tasks-templates") {
      return {
        ...response,
        data: response.data.map((task) => ({
          ...task,
          id: task.task_ID,
        })),
      };
    }
    // if (resource === "company-users") {
    //   return {
    //     ...response,
    //     data: response.data.map((company) => ({
    //       ...company,
    //       id: company.,
    //     })),
    //   };
    // }
    // if (resource === "user-role-companies") {
    //   return {
    //     ...response,
    //     data: response.data.map((company) => ({
    //       ...company,
    //       id: company.id,
    //     })),
    //   };
    // }

    return response;
  },
  create: async (resource, params) => {
    console.log(params, "THE PARAMS ARE HERE");
    if (
      (resource === "tasks" &&
        (params.data.task_JSON || params.data.thumbnail)) ||
      (resource === "user-requests" && params.data.request_JSON)
    ) {
      const formData = new FormData();
      Object.keys(params.data).forEach((key) => {
        const isFileField =
          (key === "task_JSON" ||
            key === "request_JSON" ||
            key === "thumbnail") &&
          params.data[key].rawFile;

        if (isFileField) {
          formData.append(key, params.data[key].rawFile);
        } else {
          formData.append(key, params.data[key]);
        }
      });

      const options = {
        method: "POST",
        body: formData,
      };
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      const response = await httpClient(`${apiUrl}/${resource}`, options);
      return { data: { ...params.data, id: response.json.id } };
    }

    const response = await baseDataProvider.create(resource, params);
    const data = response.data;
    return { data };
  },
};

export default dataProvider;
