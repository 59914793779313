import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
//   ReferenceInput,
//   SelectInput,
  FileField,
  FileInput,
} from "react-admin";

const UserRequestCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        {/* <ReferenceInput source="user_ID" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput source="company_ID" reference="companies">
          <SelectInput optionText="companyName" />
        </ReferenceInput> */}
        <TextInput source="user_ID" />
        <TextInput source="company_ID" />
        <FileInput
          multiple={false}
          accept="application/json"
          source="request_JSON"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default UserRequestCreate;
